import classNames from 'classnames'
import React from 'react'
import {
	AutoSizer,
	Column,
	RowMouseEventHandlerParams,
	ScrollEventData,
	Table,
} from 'react-virtualized'

import {
	cellRenderer,
	getRowClassName,
	handleTableScroll,
	headerRenderer,
} from '@components/mui-virtualized-table/mui-virtualized-table.helper'

import useStyles from './style.hook'

export interface ColumnData<T = any> {
	dataKey: keyof T | 'selectedRow'
	label: string
	width: number
	minWidth?: number
	maxWidth?: number
	sort?: string
	prefix?: string
	suffix?: string
	sortable?: boolean
	selectable?: boolean
	cellDataGetter?: (params: T) => any
}

interface Row {
	index: number
}

interface MuiVirtualizedTableProps {
	id: string
	columns: ColumnData[]
	headerHeight?: number
	height?: number
	minWidth?: number
	scrollToIndex?: number
	onRowClick?: () => void
	onRowsRendered?: void
	rowCount: number
	rowGetter: (row: Row) => any
	rowHeight?: number
	handleSortClickParent?: (dataKey: string) => void
	handleSelectAllClickParent?: () => void
	handleRowClickParent?: (params: RowMouseEventHandlerParams) => void
	handleTableScrollTrigger?: () => void
	scrollToTop?: boolean | undefined
	setScrollToTop?: React.Dispatch<React.SetStateAction<boolean | undefined>>
	hasMoreData?: boolean | undefined
	busyGettingNextPage?: boolean | undefined
	margin?: number
}

const MuiVirtualizedTable: React.FC<MuiVirtualizedTableProps> = ({
	columns,
	rowHeight = 48,
	headerHeight = 48,
	handleSortClickParent,
	handleSelectAllClickParent,
	handleRowClickParent,
	handleTableScrollTrigger,
	// 'unused' but used to remove from rest ...tableProps
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	scrollToTop,
	// 'unused' but used to remove from rest ...tableProps
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setScrollToTop,
	hasMoreData,
	busyGettingNextPage,
	onRowClick,
	height,
	margin,
	minWidth,
	...tableProps
}) => {
	const classes = useStyles(Boolean(handleRowClickParent))

	const ROW_HEIGHT = 48
	const INFINITE_SCROLL_TRIGGER = 5 * ROW_HEIGHT

	return (
		<div style={{overflowX: 'auto', margin}}>
			<div style={{height, minWidth}}>
				<AutoSizer>
					{({height, width}): React.ReactElement => (
						<Table
							headerHeight={headerHeight!}
							height={height}
							onRowClick={handleRowClickParent}
							onScroll={(eventData: ScrollEventData) =>
								handleTableScroll({
									eventData,
									INFINITE_SCROLL_TRIGGER,
									hasMoreData,
									busyGettingNextPage,
									handleTableScrollTrigger,
								})
							}
							rowHeight={rowHeight!}
							width={width}
							{...tableProps}
							rowClassName={({index}) =>
								getRowClassName({
									index,
									classes,
									onRowClick,
								})
							}
						>
							{columns.map(({dataKey, cellDataGetter, ...other}, index) => {
								return (
									<Column
										key={dataKey as string}
										cellRenderer={(data) =>
											cellRenderer({
												...data,
												columns,
												classes,
												onRowClick,
												rowHeight,
											})
										}
										className={classNames(classes.flexContainer, {
											[classes.alignRight]: dataKey
												?.toLowerCase()
												.includes('button'),
										})}
										dataKey={dataKey}
										flexGrow={1}
										cellDataGetter={({rowData}): any =>
											cellDataGetter
												? cellDataGetter(rowData)
												: rowData[dataKey]
										}
										headerRenderer={(headerProps): React.ReactNode =>
											headerRenderer({
												...headerProps,
												columnIndex: index,
												columns,
												headerHeight,
												classes,
												handleSortClickParent,
												handleSelectAllClickParent,
											})
										}
										{...other}
									/>
								)
							})}
						</Table>
					)}
				</AutoSizer>
			</div>
		</div>
	)
}

export default MuiVirtualizedTable
