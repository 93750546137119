import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside AuthWrapper functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		rolesAndLogout: {
			display: 'flex',
			gap: theme.spacing(16 / 8),
			position: 'absolute',
			right: theme.spacing(16 / 8),
			top: theme.spacing(16 / 8),
		},
		roleSelect: {
			color: theme.palette.common.white,
			'& .MuiInputLabel-root': {
				color: theme.palette.common.white,
			},
			'& .MuiSelect-select': {
				color: theme.palette.common.white,
			},
			'& .MuiSvgIcon-root': {
				color: theme.palette.common.white,
			},
		},
		loginModal: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		modalTitle: {
			marginBottom: theme.spacing(8 / 8),
		},
		modalContent: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(30 / 8),
			maxWidth: `calc((100% / 12 * 6))`,
			backgroundColor: '#FFF',
		},
	}))()

export default useStyles
