import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside GameSessionsOverview functional template
 */

const useStyles: Styling.US = (rowClickable: boolean) =>
	makeStyles((theme: Theme) => ({
		flexContainer: {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box',
		},
		headerClass: {
			backgroundColor: '#ECEDF0',
			height: 'auto !important',
		},
		tableRow: {cursor: rowClickable ? 'pointer' : 'auto'},
		tableRowHover: {'&:hover': {backgroundColor: theme.palette.grey[200]}},
		tableCell: {flex: 1},
		noClick: {cursor: 'initial'},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		tableCellBoolTrue: {color: '#4CD964'},
		tableCellBoolFalse: {color: '#FF3B30'},
		headers: {
			padding: 0,
			margin: theme.spacing(0, 16 / 8),
		},
		paginator: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-end',
			gap: theme.spacing(4 / 8),
			marginTop: theme.spacing(16 / 8),
			width: '100%',

			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-end',
				gap: theme.spacing(8 / 8),
			},
		},
		paginatorButton: {
			padding: theme.spacing(4 / 8),
			minWidth: 'unset',
			width: 32,
			height: 32,
			color: '#8a9aab',
			border: '1px solid #eef0f2',
			borderRadius: 40,

			[theme.breakpoints.up('md')]: {
				width: 40,
				height: 40,
			},
		},
		paginatorArrowButton: {
			fontWeight: 900,
		},
		currentPageButton: {
			backgroundColor: '#0A48B3',
			color: '#FFF',

			'&:hover': {
				cursor: 'initial',
				backgroundColor: '#0A48B3',
			},
		},
		alignRight: {
			'& .MuiTableCell-root': {
				justifyContent: 'flex-end',
			},
		},
		selectAllToggle: {
			cursor: 'pointer',
		},
	}))()

export default useStyles
