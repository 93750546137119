import {Input} from '@mui/material'
import React, {useState} from 'react'

import CustomButton from '@components/custom-button'
import CustomSelectInput from '@components/custom-select-input'
import {ColumnData} from '@components/mui-virtualized-table'

import CrossIconSvg from '@svg/cross-icon'

import useStyles from './style.hook'

/**
 * TableSearchHeader
 */

interface TableSearchHeaderProps {
	updateCurrentPage: (currentPage: number) => void
	defaultFilterProperty?: string
	setFilterCallback?: (filter: {value: string; property: string}) => void
	columnsToFilter: ColumnData[]
}

const TableSearchHeader: React.FC<TableSearchHeaderProps> = ({
	updateCurrentPage,
	defaultFilterProperty,
	setFilterCallback,
	columnsToFilter,
}) => {
	// Get styles from component-scoped styles hook

	const classes = useStyles()
	const [filter, setFilter] = useState<{value: string; property: string}>({
		value: null,
		property: defaultFilterProperty,
	})
	const [searchInput, setSearchInput] = useState<string>()

	return (
		<div className={classes.searchInput}>
			Search For Prize{' '}
			{
				<CustomSelectInput
					id="select-input-search-filter"
					className={classes.searchFilter}
					name={filter?.property}
					value={filter?.property}
					items={columnsToFilter.map((col) => ({
						value: col.dataKey,
						label: col.label,
					}))}
					onChange={(option: Option): void => {
						setFilter({value: filter?.value, property: option.value})
					}}
				/>
			}
			:
			<Input
				id="search-filter"
				value={searchInput}
				onChange={(e): void => setSearchInput(e.target.value)}
				onKeyDown={(e): void => {
					if (e.key === 'Enter') {
						setFilterCallback({value: searchInput, property: filter?.property})
					}
				}}
				endAdornment={
					searchInput && (
						<div
							id="close-icon"
							onClick={(): void => {
								setSearchInput('')
								setFilter({...filter, value: ''})
								setFilterCallback({...filter, value: ''})
							}}
						>
							<CrossIconSvg className={classes.searchInputErase} />
						</div>
					)
				}
			/>
			<CustomButton
				id="search-filter-button"
				onClick={(): void => {
					updateCurrentPage(1)
					setFilterCallback({value: searchInput, property: filter?.property})
				}}
			>
				Search
			</CustomButton>
		</div>
	)
}

export default TableSearchHeader
