import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material'
import classnames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * CustomTextInput
 */

interface CustomRadioInputProps {
	id: string
	className?: string
	label?: string
	defaultValue: string
	values: string[]
	name: string
	required?: boolean
	disabled?: boolean
	onChange?: (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void
}

const CustomRadioInput: React.FC<CustomRadioInputProps> = ({
	id,
	className,
	label,
	values,
	name,
	defaultValue,
	required,
	disabled,
	onChange,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div
			id={id}
			className={classnames(classes.customRadioInput, {
				[className]: className,
			})}
		>
			<FormControl>
				<FormLabel
					required={required}
					className={classes.label}
					component="legend"
				>
					{label}
				</FormLabel>
				<RadioGroup
					row={true}
					aria-label={label}
					name={name}
					defaultValue={defaultValue}
					onChange={onChange}
				>
					{values?.map((value) => (
						<FormControlLabel
							key={value}
							value={value}
							control={<Radio disabled={disabled} color="primary" />}
							label={value}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</div>
	)
}

export default CustomRadioInput
