import {alpha, Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside HeaderLayout functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		headerLayout: () => ({
			display: 'grid',
			gridTemplateColumns: '1fr',
			background: '#0A48B3',
			color: theme.palette.common.white,
			fontWeight: 500,
			fontSize: theme.spacing(20 / 8),
			padding: `4rem ${theme.spacing(16 / 8)} 6rem`,
			gap: '0.5rem',
			boxShadow: `0px 4px 8px ${alpha('#0A48B3', 0.4)}`,
			border: 'none',
			[theme.breakpoints.up('sm')]: {
				padding: `5rem ${theme.spacing(40 / 8)} 6rem`,
				gridTemplateColumns: '3fr auto',
				gap: '0',
			},
			[theme.breakpoints.up('md')]: {
				padding: `8rem ${theme.spacing(40 / 8)}`,
			},
		}),
		titleContainer: {
			// Relative position and z-index of 1000, so as still have clickable breadcrumbs, with the overlaying notification layer
			position: 'relative',
			zIndex: 1000,
			display: 'flex',
			gap: theme.spacing(18 / 8),
			alignItems: 'center',
			'& .breadcrumb__link': {
				display: 'flex',
			},
			'& .breadcrumb__link__active': {
				pointerEvents: 'none',
			},
		},
	}))()

export default useStyles
