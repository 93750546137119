import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTextInput functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			customRadioInput: {
				width: '100%',
				borderRadius: theme.spacing(6 / 8),
				padding: theme.spacing(5 / 8),
			},
			label: {
				color: '#586483',
				fontSize: theme.spacing(18 / 8),
				marginBottom: theme.spacing(10 / 8),
			},
		}
	})()

export default useStyles
