import {Home} from '@mui/icons-material'
import {PageProps} from 'gatsby'
import {Breadcrumb} from 'gatsby-plugin-breadcrumb'
import React from 'react'

import useStyles from './style.hook'

/**
 * HeaderLayout
 */

interface HeaderLayoutProps extends Partial<PageProps> {
	title?: string
	crumbs?: any[]
	endButton?: React.ReactNode
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({
	title,
	location,
	crumbs,
	endButton,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const crumbsWithIcon = crumbs?.map((crumb) =>
		crumb.crumbLabel === 'Home' ? {...crumb, crumbLabel: <Home />} : crumb
	)

	return (
		<div id="header-layout" className={classes.headerLayout}>
			<div className={classes.titleContainer}>
				{title}
				<div className={classes.breadcrumb}>
					<Breadcrumb
						crumbSeparator="-"
						crumbs={crumbsWithIcon}
						location={location}
					/>
				</div>
			</div>
			{endButton}
		</div>
	)
}

export default HeaderLayout
