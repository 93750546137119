import enumToMap from '@utils/enum-to-map'

export enum Permissions {
	GAMETYPES_READ = 'Read game types',
	GAMETYPES_WRITE = 'Write game types',
	HOME_KPIS = `Read home kpi's`,
	MACHINES_READ = 'Read machines',
	MACHINES_WRITE = 'Write machines',
	ORDERS_READ = 'Read orders',
	ORDERS_WRITE = 'Write orders',
	PLAYER_GAME_SESSIONS_READ = 'Read player game sessions',
	PLAYER_GAME_SESSIONS_WRITE = 'Write player game sessions',
	PLAYER_PROFILES_READ = 'Read player profiles',
	PLAYER_PROFILES_WRITE = 'Write player profiles',
	PLAYER_PURCHASES_READ = 'Read player purchases',
	PLAYER_PURCHASES_WRITE = 'Write player purchases',
	PLAYER_TRANSACTIONS_READ = 'Read player transactions',
	PLAYER_TRANSACTIONS_WRITE = 'Write player transactions',
	PRIZES_READ = 'Read prizes',
	PRIZES_WRITE = 'Write prizes',
	PRIZE_CATEGORIES_READ = 'Read prize categories',
	PRIZE_CATERGORIES_WRITE = 'Write prize categories',
	SHOP_READ = 'Read shop',
	SHOP_WRITE = 'Write shop',
	TENANTS_READ = 'Read tenants',
	TENANTS_WRITE = 'Write tenants',
	TENANT_SETTINGS_READ = 'Read tenant settings',
	TENANT_SETTINGS_WRITE = 'Write tenant settings',
	INCIDENTS_READ = 'Read incidents',
	INCIDENTS_WRITE = 'Write incidents',
}

export const PERMISSIONS_MAP = enumToMap(Permissions)
