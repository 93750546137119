import {
	Assessment,
	Assignment,
	AssignmentInd,
	Category,
	ChevronRight,
	House,
	KeyboardArrowDown,
	Menu,
	Person,
	Storefront,
	Toys,
	VideogameAsset,
	Warning,
} from '@mui/icons-material'
import {
	Box,
	Collapse,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import {Permissions} from '@roles/permissions'
import {roleHasPermission} from '@roles/roles'
import classNames from 'classnames'
import {navigate} from 'gatsby'
import React, {useMemo, useState} from 'react'

import {useGetAuthStateQuery} from '@redux/slices/auth.slice'

import useStyles from './style.hook'

/**
 * SidebarLayout
 */
interface SidebarLayoutItems {
	id?: string
	title: string
	logo?: React.ReactNode
	path?: string
	open?: boolean
	items?: {title: string; logo?: React.ReactNode; path: string}[]
}

interface SidebarHeaderProps {
	logo: React.ReactNode
	title: string
}

const SidebarLayout: React.FC = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const theme = useTheme()

	const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

	const [mobileOpen, setMobileOpen] = React.useState(false)

	const {data: authState, isLoading: isLoadingAuthState} =
		useGetAuthStateQuery(null)

	const location: Location =
		process.browser === true ? window.location : undefined

	const [openedItem, setOpenedItem] = useState<string>()
	const sidebarLayoutItems = useMemo<SidebarLayoutItems[]>(
		() =>
			[
				{
					id: 'sidebar-layout-item-dashboard',
					title: 'Dashboard',
					path: '/',
					logo: <House style={{fill: '#0a49b2'}} />,
					open: openedItem === 'Dashboard',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.HOME_KPIS
					),
				},
				{
					id: 'sidebar-layout-item-tenants',
					title: authState?.userData?.isElautUser
						? 'Tenants'
						: 'Tenant Settings',
					path: `/tenants${
						authState?.userData?.isElautUser
							? ''
							: `/detail?id=${authState?.userData?.tenantId}`
					}`,
					logo: <AssignmentInd style={{fill: '#ffd600'}} />,
					open: openedItem?.includes('Tenant'),
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.TENANTS_READ
					),
				},
				{
					id: 'sidebar-layout-item-game-types',
					title: 'Game Types',
					path: '/game-types',
					logo: <VideogameAsset style={{fill: '#1acdef'}} />,
					open: openedItem === 'Game Types',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.GAMETYPES_READ
					),
				},
				{
					id: 'sidebar-layout-item-machines',
					title: 'Machines',
					path: '/machines',
					logo: <Storefront style={{fill: '#2ece89'}} />,
					open: openedItem === 'Machines',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.MACHINES_READ
					),
				},
				{
					id: 'sidebar-layout-item-prizes',
					title: 'Prizes',
					path: '/prizes',
					logo: <Toys style={{fill: '#ff00ff'}} />,
					open: openedItem === 'Prizes',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.PRIZES_READ
					),
				},
				{
					id: 'sidebar-layout-item-prize-categories',
					title: 'Prize Categories',
					path: '/prize-categories',
					logo: <Category style={{fill: '#ffa500'}} />,
					open: openedItem === 'Prize Categories',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.PRIZE_CATEGORIES_READ
					),
				},
				{
					id: 'sidebar-layout-item-orders',
					title: 'Orders',
					path: '/orders',
					logo: <Assignment style={{fill: '#0377fc'}} />,
					open: openedItem === 'Orders',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.ORDERS_READ
					),
				},
				{
					id: 'sidebar-layout-item-players',
					title: 'Players/Users',
					path: '/players',
					logo: <Person style={{fill: '#f54265'}} />,
					open: openedItem === 'Players/Users',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.PLAYER_PROFILES_READ
					),
				},
				{
					id: 'sidebar-layout-item-incidents',
					title: 'Incidents',
					path: '/incidents',
					logo: <Warning style={{fill: '#ffa500'}} />,
					open: openedItem === 'Incidents',
					hasReadRights: roleHasPermission(
						authState?.userData?.role,
						Permissions.INCIDENTS_READ
					),
				},
				{
					id: 'sidebar-layout-item-environment',
					title: 'Local build',
					logo: <Assessment style={{fill: '#5d5d5d'}} />,
					open: false,
					hasReadRights: Boolean(process.env.GATSBY_LOCAL_BUILD),
				},
			].filter((item) => {
				if (!authState?.userData?.isElautUser) {
					if (item.title === 'Game Types') {
						return false
					}
				}

				if (!item.hasReadRights) {
					return false
				}

				return true
			}),
		[authState, openedItem]
	)

	const handleDrawerToggle = (): void => {
		setMobileOpen(!mobileOpen)
	}

	const navigateToPage = (path: string): void => {
		navigate(path)
		if (isMobile) {
			handleDrawerToggle()
		}
	}

	const isActiveSidebarItem = (path: string): boolean =>
		path === '/'
			? location?.pathname === '/'
			: `${location?.pathname}${location?.search}`.includes(path)

	const SidebarHeader: React.FC<SidebarHeaderProps> = ({title, logo}) => {
		return (
			<Box id={'sidebar-header'} className={classes.sidebarHeader}>
				<Box className={classes.itemLogo}>{logo}</Box>
				<Typography variant={'h6'}>{title}</Typography>
			</Box>
		)
	}

	const drawer = (
		<Box>
			<SidebarHeader title={'Arcade'} logo={<Storefront />} />
			<List disablePadding>
				{sidebarLayoutItems.map((item) => (
					<ListItem
						id={item.id}
						className={classes.sidebarMenuItem}
						button={Boolean(!item.items)}
						key={item.title}
						onClick={(): void => item.path && navigateToPage(item.path)}
					>
						<Box
							className={classes.drawerItem}
							onClick={(): void => setOpenedItem(item.title)}
						>
							<Box>
								<Box
									className={classNames(
										classes.itemLogo,
										isActiveSidebarItem(item.path) ? classes.activeItem : null
									)}
								>
									{item.logo}
								</Box>
								<ListItemText primary={item.title} />
							</Box>
							{item.items && item.open ? (
								<KeyboardArrowDown />
							) : item.items ? (
								<ChevronRight />
							) : null}
						</Box>
						{item.items && (
							<Collapse
								className={classes.submenu}
								in={item.items && item.open}
								component={'li'}
							>
								<List disablePadding>
									{item.items.map((subItem) => (
										<ListItem
											key={subItem.title}
											button
											onClick={(): void => navigateToPage(subItem.path)}
										>
											<Box
												className={classNames(
													classes.itemLogo,
													isActiveSidebarItem(subItem.path)
														? classes.activeItem
														: null
												)}
											>
												{subItem.logo}
											</Box>
											<ListItemText secondary={subItem.title} />
										</ListItem>
									))}
								</List>
							</Collapse>
						)}
					</ListItem>
				))}
			</List>
		</Box>
	)

	return (
		<div id="sidebar-layout" className={classes.sidebarLayout}>
			<IconButton
				className={classes.menuItem}
				color="inherit"
				aria-label="open drawer"
				edge="start"
				onClick={handleDrawerToggle}
				size="large"
			>
				<Menu />
			</IconButton>
			{!isLoadingAuthState && authState && (
				<>
					<Drawer
						className={classes.mobileDrawer}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
					<Drawer className={classes.desktopDrawer} variant="permanent" open>
						{drawer}
					</Drawer>
				</>
			)}
		</div>
	)
}

export default SidebarLayout
