import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {RootState} from '@redux/root-reducer'

type ErrorSliceState = {
	errors: BackendErrorType[]
}

const initialState: ErrorSliceState = {
	errors: [],
}

const errorsSlice = createSlice({
	name: 'errorsSlice',
	initialState,
	reducers: {
		addError(state, action: PayloadAction<any>): void {
			const errorPayload = action.payload.payload
			state.errors = [...state.errors, {...errorPayload}]
		},
		resetAllErrors(): ErrorSliceState {
			return {...initialState}
		},
		resetErrorsWithoutViolations(state): void {
			state.errors = [...state.errors.filter((error) => error.violations)]
		},
		// Filter out violation error
		resetErrorWithViolation(state, action: PayloadAction<string>): void {
			state.errors = [
				...state.errors.filter(
					(error) =>
						!error.violations.filter(
							(violation) => violation.message === action.payload
						)
				),
			]
		},
	},
})

export const {
	addError,
	resetAllErrors,
	resetErrorsWithoutViolations,
	resetErrorWithViolation,
} = errorsSlice.actions

// Violations are only used for form validations, so there will only be 1 error of this kind.
export const selectValidationError = (state: RootState): any =>
	state.errorsSlice.errors.find((error) => error.violations)
export const selectErrorsWithoutViolations = (state: RootState): any =>
	state.errorsSlice.errors.filter((error) => !error.violations)

export default errorsSlice
