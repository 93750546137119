const getUrl = (
	basePath: string,
	options?: {appendPath?: string; queryParams?: Map<string, string>}
): URL => {
	const url = options?.appendPath
		? new URL(options.appendPath, basePath)
		: new URL(basePath)

	if (options?.queryParams) {
		options.queryParams.forEach((value, key) => {
			if (key && value) {
				url.searchParams.set(key, value)
			}
		})
	}

	return url
}

export default getUrl
