import {Button, ButtonProps} from '@mui/material'
import classnames from 'classnames'
import React, {forwardRef} from 'react'

import CustomTooltip from '@components/custom-tooltip/custom-tooltip'

import {TooltipValue} from '@utils/tooltipMap'

import useStyles from './style.hook'

/**
 * CustomButton
 */

type Ref = HTMLButtonElement

export interface CustomButtonProps extends ButtonProps {
	customColor?: string
	invertColors?: boolean
	tooltipValue?: TooltipValue
}

const CustomButton = forwardRef<Ref, CustomButtonProps>((props, ref) => {
	const {className, customColor, children, invertColors, tooltipValue} = props

	// Get styles from component-scoped styles hook
	const classes = useStyles({
		customColor,
	})

	return (
		<CustomTooltip title={tooltipValue}>
			<Button
				{...props}
				ref={ref}
				className={classnames(
					classes.customButton,
					{
						[className]: className,
					},
					{[classes.invertedButton]: invertColors}
				)}
			>
				{children}
			</Button>
		</CustomTooltip>
	)
})

CustomButton.displayName = 'CustomButton'

export default CustomButton
