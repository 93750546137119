import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside TableSearchHeader functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			searchFilter: {
				maxWidth: 200,
			},
			searchInput: {
				gridColumn: '4',
				display: 'flex',
				alignItems: 'center',
				gap: theme.spacing(16 / 8),
			},
			searchInputErase: {
				marginLeft: theme.spacing(8 / 8),
				cursor: 'pointer',
			},
		}
	})()

export default useStyles
