import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Hidden from '@mui/material/Hidden'
import StylesProvider from '@mui/styles/StylesProvider'
import * as React from 'react'
import {useEffect} from 'react'

import getMuiThemeContext from './mui-theme-context'

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

/**
 * MuiThemeWrapper
 */
interface MuiThemeWrapperProps {
	children: React.ReactNode
}

export const MuiThemeWrapper: React.FC<MuiThemeWrapperProps> = ({children}) => {
	const muiThemeContext = getMuiThemeContext()

	useEffect(() => {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles && jssStyles.parentNode) {
			jssStyles.parentNode.removeChild(jssStyles)
		}
	}, [])

	return (
		<StylesProvider
			generateClassName={muiThemeContext.generateClassName}
			sheetsManager={muiThemeContext.sheetsManager}
		>
			{/* ThemeProvider makes the theme available down the React
          tree thanks to React context. */}
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={muiThemeContext.theme}>
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					<Hidden implementation="css">{children}</Hidden>
				</ThemeProvider>
			</StyledEngineProvider>
		</StylesProvider>
	)
}

export default MuiThemeWrapper
