import {createApi} from '@reduxjs/toolkit/query/react'

import {jsonBaseQuery} from '@redux/base-queries'

// Initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: jsonBaseQuery(),
	tagTypes: [
		'Tenants',
		'TenantSettings',
		'GameTypes',
		'Machines',
		'Certificates',
		'PlayerProfiles',
		'Prizes',
		'PlayerPrizes',
		'PlayerTransactions',
		'Orders',
		'Incidents',
		'IncidentLog',
	],
	endpoints: () => ({}),
})

export default baseApi
