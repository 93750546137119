import * as React from 'react'
import {Helmet} from 'react-helmet'

import {I18nWrapper} from '@i18n/index'

import {SidebarLayout} from '@layouts/index'
import NotificationLayout from '@layouts/notification-layout'

import {AuthWrapper} from '@wrappers/index'

import {CommonPageContext} from '@utils/types'

import {MuiThemeWrapper} from '@styles/index'
import '@styles/root.scss'

/**
 * TopLayout
 */
interface TopLayoutProps {
	children: React.ReactNode
	pageContext: CommonPageContext
	location: Location
}

export const TopLayout: React.FC<TopLayoutProps> = ({
	children,
	pageContext,
	location,
}) => {
	return (
		<div id="top-layout">
			<Helmet>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />
				<link
					href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<MuiThemeWrapper>
				<I18nWrapper languageFromPath={pageContext.pathLanguageIso}>
					<AuthWrapper
						isPrivateRoute={pageContext.isPrivate}
						requiredPermissions={pageContext.requiredPermissions}
						location={location}
					>
						<NotificationLayout />
						{location.pathname !== '/admin-login' && <SidebarLayout />}
						{children}
					</AuthWrapper>
				</I18nWrapper>
			</MuiThemeWrapper>
		</div>
	)
}

export default TopLayout
