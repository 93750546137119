import {useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {errorsSlice} from '@redux/slices'

type UseErrorHandlingReturnType = {
	getValidationErrorMessage: (
		error: BackendErrorType,
		property: string
	) => string
	resetValidationErrorOnInputChange: (
		validationErrorMessage: string | undefined
	) => void
}

const useErrorHandling = (): UseErrorHandlingReturnType => {
	const dispatch = useDispatch()
	const getValidationErrorMessage = (
		error: BackendErrorType,
		property: string
	): string => {
		return error?.violations
			?.filter((violation) => violation.property.includes(property))
			?.map((violation) => violation.message)
			.join(', \n')
	}

	const resetValidationErrorOnInputChange = (
		validationErrorMessage: string | undefined
	): void => {
		if (validationErrorMessage) {
			dispatch(errorsSlice.actions.resetErrorWithViolation())
		}
	}

	return {
		getValidationErrorMessage,
		resetValidationErrorOnInputChange,
	}
}

type UseErrorHandlingV2ReturnType<Fields extends string> = {
	validationMessagesByField: Partial<Record<Fields, string>>
	resetValidationErrorOnInputChange: (
		validationErrorMessage: string | undefined
	) => void
}

const useErrorHandlingV2 = <Fields extends string>(
	validationError?: BackendErrorType
): UseErrorHandlingV2ReturnType<Fields> => {
	const dispatch = useDispatch()
	const validationMessagesByField = useMemo((): Partial<
		Record<Fields, string>
	> => {
		if (validationError && validationError.violations?.length) {
			return (validationError as BackendErrorType).violations!.reduce(
				(acc, violation) => {
					const {property, message} = violation
					const field = property as Fields
					acc[field] = acc[field] ? `${acc[field]}, \n${message}` : message
					return acc
				},
				{} as Record<Fields, string>
			)
		}
		return {}
	}, [validationError])

	const resetValidationErrorOnInputChange = (
		validationErrorMessage: string | undefined
	): void => {
		if (validationErrorMessage) {
			dispatch(
				errorsSlice.actions.resetErrorWithViolation(validationErrorMessage)
			)
		}
	}

	return {
		validationMessagesByField,
		resetValidationErrorOnInputChange,
	}
}

export default useErrorHandling
export {useErrorHandlingV2}
