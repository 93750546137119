import {Close} from '@mui/icons-material'
import {Dialog} from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * CustomDialog
 */

interface CustomDialogProps {
	className?: string
	id?: string
	isOpen: boolean
	hideCloseIcon?: boolean
	title?: React.ReactNode
	primaryActionButton?: React.ReactNode
	secondaryActionButton?: React.ReactNode
	onClose: () => void
	children: React.ReactNode
}

const CustomDialog: React.FC<CustomDialogProps> = ({
	className,
	id,
	isOpen,
	title,
	onClose,
	primaryActionButton,
	secondaryActionButton,
	children,
	hideCloseIcon,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<Dialog
			id={id}
			className={classNames(classes.dialog, {[className]: className})}
			open={isOpen}
			onClose={onClose}
			disableScrollLock
		>
			{!hideCloseIcon && (
				<Close className={classes.closeButton} onClick={onClose} />
			)}
			<div className={classes.title}>{title}</div>
			{children}
			<div className={classes.buttonContainer}>
				{primaryActionButton}
				{secondaryActionButton}
			</div>
		</Dialog>
	)
}

export default CustomDialog
