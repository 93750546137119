import {createApi} from '@reduxjs/toolkit/query/react'

import {jsonBaseQuery} from '@redux/base-queries'

const AUTH_ENDPOINT = 'auth'
const USERS_ENDPOINT = 'users'

const responseStatusToAuthStatusType = (response: Response): AuthStatusType => {
	return [401, 403, 404].includes(response.status)
		? 'UNAUTHORISED'
		: 'AUTHORISED'
}

export const authApi = createApi({
	reducerPath: 'auth',
	baseQuery: jsonBaseQuery(),
	tagTypes: ['AuthStatus'],
	endpoints: (builder) => ({
		getAuthState: builder.query<AuthStatus | null, null>({
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				try {
					const response = await baseQuery({
						input: `${process.env.GATSBY_BACKOFFICE_BASE_URL}/${USERS_ENDPOINT}/me`,
						init: {method: 'GET', credentials: 'include'},
					})

					const meta = response.meta as {response: Response}
					const userData = response.data as AuthStatus['userData']
					const authStatusType = responseStatusToAuthStatusType(meta.response)
					const isAuthorised = authStatusType === 'AUTHORISED'
					isAuthorised &&
						window.localStorage.setItem('isRegisteredUser', 'true')
					isAuthorised &&
						userData.identityProviderId &&
						window.localStorage.setItem(
							'lastUserIdpId',
							userData.identityProviderId
						)

					return {
						data: {
							type: authStatusType,
							userData: isAuthorised ? userData : undefined,
						},
					}
				} catch (error) {
					return {
						data: {
							type: 'UNAUTHORISED',
							userData: null,
						},
					}
				}
			},
			providesTags: ['AuthStatus'],
		}),
		login: builder.query<void, 'elaut' | 'tenant'>({
			queryFn: (type) => {
				try {
					const redirectUrl = `${
						process.env.GATSBY_AUTH_API_BASE_URL
					}/${AUTH_ENDPOINT}/${
						type === 'elaut'
							? process.env.GATSBY_ELAUT_IDP_ID
							: process.env.GATSBY_TENANT_IDP_ID
					}/callback?backoffice=true`
					const loginUrl = new URL(
						type === 'elaut'
							? process.env.GATSBY_ELAUT_COGNITO_DOMAIN
							: `${process.env.GATSBY_TENANT_IDP_DOMAIN}/authorize`
					)
					loginUrl.searchParams.set(
						'client_id',
						type === 'elaut'
							? process.env.GATSBY_ELAUT_COGNITO_CLIENT_ID
							: process.env.GATSBY_TENANT_IDP_CLIENT_ID
					)
					loginUrl.searchParams.set('response_type', 'code')
					loginUrl.searchParams.set(
						'scope',
						`email openid profile ${type === 'tenant' ? 'offline_access' : ''}`
					)
					loginUrl.searchParams.set('redirect_uri', redirectUrl)

					if (type === 'tenant') {
						loginUrl.searchParams.set(
							'audience',
							process.env.GATSBY_TENANT_AUDIENCE
						)
					}

					window.location.href = loginUrl.toString()

					// Will never come here since we redirect
					return {data: null}
				} catch (error) {
					return {error: error.message}
				}
			},
		}),
		logout: builder.query<void, 'elaut' | 'tenant'>({
			queryFn: (type) => {
				try {
					window.localStorage.removeItem('lastUserIdpId')

					window.location.href =
						type === 'elaut'
							? `${process.env.GATSBY_AUTH_API_BASE_URL}/${AUTH_ENDPOINT}/${process.env.GATSBY_ELAUT_IDP_ID}/logout?backoffice=true`
							: `${process.env.GATSBY_TENANT_IDP_DOMAIN}/v2/logout?client_id=${process.env.GATSBY_TENANT_IDP_CLIENT_ID}&returnTo=${process.env.GATSBY_AUTH_API_BASE_URL}/${AUTH_ENDPOINT}/${process.env.GATSBY_TENANT_IDP_ID}/logout?backoffice=true`

					// Will never come here since we redirect
					return {data: null}
				} catch (error) {
					return {error: error.message}
				}
			},
		}),
	}),
})

export const {
	useGetAuthStateQuery,
	useLazyGetAuthStateQuery,
	useLazyLoginQuery,
	useLazyLogoutQuery,
} = authApi
export default authApi
