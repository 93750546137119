import {Tooltip, TooltipProps} from '@mui/material'
import classnames from 'classnames'
import React from 'react'

import useStyles from './custom-tooltip.styles'

const CustomTooltip: React.FC<TooltipProps> = ({
	children,
	slotProps,
	...other
}) => {
	const classes = useStyles()

	return (
		<Tooltip
			placement={'top-start'}
			enterNextDelay={300}
			{...other}
			slotProps={{
				...slotProps,
				tooltip: {
					...slotProps?.tooltip,
					className: classnames(slotProps?.tooltip?.className, classes.tooltip),
				},
			}}
		>
			{children}
		</Tooltip>
	)
}

export default CustomTooltip
