import {
	Action,
	configureStore,
	isRejectedWithValue,
	MiddlewareAPI,
} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/dist/query'
import {ThunkAction} from 'redux-thunk'

import rootReducer, {RootState} from '@redux/root-reducer'

import {authApi, baseApi, errorsSlice} from './slices'

const rtkQueryErrorLogger =
	(api: MiddlewareAPI) =>
	(next) =>
	(action): any => {
		// eslint-disable-next-line no-console
		console.log('action in store', action)

		// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
		if (isRejectedWithValue(action)) {
			if (!(typeof window === 'undefined')) {
				api.dispatch(errorsSlice.actions.addError(action))
			}
		}

		return next(action)
	}

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleWare) =>
		getDefaultMiddleWare().concat(
			baseApi.middleware,
			authApi.middleware,
			rtkQueryErrorLogger
		),
})

const enableRefetchListeners = (enable: boolean): void => {
	if (enable) {
		setupListeners(store.dispatch)
	}
}

/**
 * Change this rule to true if you want to enable refetchOnFocus and
 * refetchOnReconnect behaviors on query subsciptions.
 */
enableRefetchListeners(false)

if (process.env.NODE_ENV === 'local' && module.hot) {
	module.hot.accept('./root-reducer', () => {
		const newRootReducer = require('./root-reducer').default
		store.replaceReducer(newRootReducer)
	})
}

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
