import {i18n as i18nType} from 'i18next'

import {windowNavigatorExists} from '@utils/browser-utils'

import {DEFAULT_LANGUAGE} from './config'
import locales from './locales'

export const getLangFromBrowser = (): string => {
	const languages = Object.keys(locales)
	const browserLanguage =
		windowNavigatorExists() && window.navigator.language.slice(0, 2)

	return (
		(languages.includes(browserLanguage) && browserLanguage) || DEFAULT_LANGUAGE
	)
}

export const changeLanguage = async (
	i18n: i18nType,
	langFromPath: string
): Promise<string> => {
	let lang = langFromPath

	// No language in URL path; take Browser language
	if (!langFromPath && windowNavigatorExists()) {
		lang = window.navigator.language.slice(0, 2)
	}

	if (!lang) {
		lang = DEFAULT_LANGUAGE
	}

	await i18n.changeLanguage(lang)

	return lang
}
