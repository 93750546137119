/* eslint-disable */
import * as React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import store from './src/redux/store';
import { i18n } from './src/i18n/index';

import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const cache = createCache({ key: 'css' });

/* Provide app with i18n */
export const wrapRootElement = ({ element }) => {
	return (
		<CacheProvider value={cache}>
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>{element}</I18nextProvider>
			</Provider>
		</CacheProvider>
	);
};
