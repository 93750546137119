module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#3b3b39","theme_color":"#3b3b39","display":"standalone","icon":"./static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7406b077375ae580a1be5b8ae452155d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src3626887676/src/xg-livecadia-dashboard-web/src/layouts/top-layout/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"defaultCrumb":{"location":{"pathname":"/"},"crumbLabel":"Home","crumbSeparator":"-"},"crumbLabelUpdates":[{"pathname":"/tenants","crumbLabel":"Tenants"},{"pathname":"/tenants/detail","crumbLabel":"Add Tenant"},{"pathname":"/players","crumbLabel":"Players"},{"pathname":"/players/detail","crumbLabel":"Edit Player"},{"pathname":"/machines","crumbLabel":"Machines"},{"pathname":"/machines/detail","crumbLabel":"Add Machine"},{"pathname":"/game-types","crumbLabel":"Game Types"},{"pathname":"/game-types/detail","crumbLabel":"Edit Game Type"},{"pathname":"/prizes","crumbLabel":"Prizes"},{"pathname":"/prizes/detail","crumbLabel":"Edit Prize"},{"pathname":"/prize-categories","crumbLabel":"Prize Categories"},{"pathname":"/prize-categories/detail","crumbLabel":"Edit Category"},{"pathname":"/orders","crumbLabel":"Orders"},{"pathname":"/orders/detail","crumbLabel":"Order Detail"},{"pathname":"/incidents","crumbLabel":"Incidents"},{"pathname":"/incidents/detail","crumbLabel":"Incident Detail"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
