import EN from './en'
import FR from './fr'
import NL from './nl'

export default {
	en: {
		translations: {
			...EN,
		},
	},
	nl: {
		translations: {
			...NL,
		},
	},
	fr: {
		translations: {
			...FR,
		},
	},
}
