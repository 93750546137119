import React, {useEffect, useState} from 'react'
import toast, {useToasterStore} from 'react-hot-toast'

import {usePrevious} from '@utils/hooks/index'

/**
 * CustomToast
 */

type UniqueAmount = {
	uuid: string
	amount: number
}

type CustomToastProps = Pick<CommonProps, 'className' | 'id'> & {
	uniqueAmount?: UniqueAmount
	pinToElementRef: React.MutableRefObject<any>
	component: React.ReactElement
}

const CustomToast: React.FC<CustomToastProps> = ({
	uniqueAmount,
	pinToElementRef,
	component,
}) => {
	// Get styles from component-scoped styles hook
	const {toasts} = useToasterStore()
	const [amountStack, setAmountStack] = useState<number>(0)
	const previousUniqueAmount = usePrevious<UniqueAmount>(uniqueAmount)

	useEffect(() => {
		if (toasts.length > 5) {
			toasts.slice(5).forEach((t) => {
				toast.dismiss(t.id)
			})
		}
	}, [toasts])

	useEffect(() => {
		if (uniqueAmount && uniqueAmount.uuid !== previousUniqueAmount?.uuid) {
			setAmountStack((n) => n + uniqueAmount.amount)
		}
	}, [previousUniqueAmount, uniqueAmount])

	useEffect(() => {
		if (amountStack <= 0) {
			return
		}

		if (amountStack && pinToElementRef.current) {
			const clientRect = pinToElementRef.current?.getBoundingClientRect()
			const widthOffset = clientRect.left
			const heightOffset = clientRect.height + clientRect.top - 50

			const timeout = setTimeout(() => {
				toast.custom(
					<div style={{marginTop: heightOffset, marginLeft: widthOffset}}>
						{React.cloneElement(component, {
							amount: amountStack.toString(),
						})}
					</div>,
					{
						position: 'top-left',
						duration: 1000,
					}
				)
				setAmountStack(0)
			}, 1000)
			return (): void => {
				clearTimeout(timeout)
			}
		}
	}, [amountStack, component, pinToElementRef])

	return <></>
}

export default CustomToast
