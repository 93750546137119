export const deleteProperties = (
	object: Partial<TenantDetail>,
	properties: string[]
) => {
	properties.forEach((property) => {
		if (Object.keys(object).includes(property)) {
			delete object[property as keyof TenantDetail]
		}
	})
	return object
}
