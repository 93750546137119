import classnames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * TabHeader
 */

interface TabHeaderProps {
	className?: string
	children?: React.ReactNode
}

const TabHeader: React.FC<TabHeaderProps> = ({className, children}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div
			className={classnames(classes.tabHeader, {
				[className]: className,
			})}
		>
			{children}
		</div>
	)
}

export default TabHeader
