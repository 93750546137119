import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {FormControl, FormLabel} from '@mui/material'
import classnames from 'classnames'
import React from 'react'
import 'react-select/dist/react-select.css'
import Select from 'react-virtualized-select'
import 'react-virtualized-select/styles.css'

import CustomTooltip from '@components/custom-tooltip/custom-tooltip'

import {TooltipValue} from '@utils/tooltipMap'

import useStyles from './style.hook'

/**
 * CustomTextInput
 */

interface CustomSelectInputProps {
	id: string
	className?: string
	label?: string
	value: string[] | string
	name: string
	items: {label: string; value: string}[]
	multiple?: boolean
	clearable?: boolean
	required?: boolean
	error?: string | string[]
	disabled?: boolean
	onChange?: (selected: Option | Option[]) => void
	minWidth?: number
	tooltipValue?: TooltipValue
}

const CustomSelectInput: React.FC<CustomSelectInputProps> = ({
	id,
	className,
	label,
	value,
	name,
	multiple = false,
	clearable = false,
	items,
	required,
	disabled,
	error,
	onChange,
	minWidth,
	tooltipValue,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div
			id={id}
			className={classnames(classes.customSelectInput, {
				[className]: className,
			})}
		>
			<FormControl fullWidth size="small" sx={{minWidth}}>
				<div className={classes.labelContainer}>
					<FormLabel required={required} component="legend">
						{label}
					</FormLabel>
					<span className={classes.error}>
						{Array.isArray(error) ? error.toString() : error}
					</span>

					{tooltipValue && (
						<CustomTooltip title={tooltipValue}>
							<HelpOutlineIcon
								sx={{
									fontSize: 22,
								}}
							/>
						</CustomTooltip>
					)}
				</div>
				<Select
					options={items}
					name={name}
					value={value}
					multi={multiple}
					clearable={clearable}
					disabled={disabled}
					onChange={onChange}
				/>
			</FormControl>
		</div>
	)
}

export default CustomSelectInput
