import {TableCell, TableCellProps, TableSortLabel, Tooltip} from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import {ScrollEventData, TableHeaderProps} from 'react-virtualized'

import {ColumnData} from '@components/mui-virtualized-table/index'

interface HeaderRendererArgs {
	columnIndex: number
	columns: ColumnData[]
	headerHeight: number
	classes: any
	handleSortClickParent: (dataKey: string) => void
}

export const headerRenderer = ({
	label,
	dataKey,
	columnIndex,
	columns,
	headerHeight,
	classes,
	handleSortClickParent,
	handleSelectAllClickParent,
}: TableHeaderProps & HeaderRendererArgs): React.ReactElement => {
	const handleSortClick = (): void => {
		handleSortClickParent(dataKey)
	}
	const handleSelectAllClick = (): void => {
		handleSelectAllClickParent()
	}

	/** Needs comment on how this works */
	const sortDirection = columns[columnIndex].sort
		? columns[columnIndex].sort === 'asc'
			? (columns[columnIndex].sort as 'asc')
			: columns[columnIndex].sort === 'desc'
			? (columns[columnIndex].sort as 'desc')
			: false
		: false

	return (
		<TableCell
			align="left"
			className={classNames(
				classes.tableCell,
				classes.flexContainer,
				classes.noClick,
				classes.headers
			)}
			component="div"
			sortDirection={sortDirection}
			style={{height: headerHeight}}
			variant="head"
		>
			{columns[columnIndex].sortable ? (
				<TableSortLabel
					active={Boolean(sortDirection)}
					direction={
						sortDirection ? (sortDirection as 'asc' | 'desc') : undefined
					}
					onClick={handleSortClick}
				>
					<span>{label}</span>
				</TableSortLabel>
			) : columns[columnIndex].selectable ? (
				<span
					className={classNames(classes.selectAllToggle)}
					onClick={handleSelectAllClick}
				>
					<span>{label}</span>
				</span>
			) : (
				<span>{label}</span>
			)}
		</TableCell>
	)
}

export const getTooltipValue = (cellData: any): string => {
	if (typeof cellData === 'boolean') {
		return cellData ? '✔' : '✗'
	}

	return cellData || ('' as string)
}

export const getCellData = (cellData: any): string => {
	if (typeof cellData === 'boolean') {
		return cellData ? '✔' : '✗'
	}

	if (Array.isArray(cellData)) {
		return cellData[0] || '-'
	}

	return cellData || '-'
}

interface CellRendererArgs extends TableCellProps {
	columns: ColumnData[]
	classes: any
	onRowClick: () => void
	rowHeight: number
}

type CellRenderer = (params: CellRendererArgs) => React.ReactNode
export const cellRenderer: CellRenderer = ({
	cellData,
	columnIndex,
	columns,
	classes,
	onRowClick,
	rowHeight,
}: CellRendererArgs) => {
	const {suffix, selectable} = columns[columnIndex]

	return (
		<Tooltip
			disableHoverListener={React.isValidElement(cellData)}
			title={getTooltipValue(Array.isArray(cellData) ? cellData[1] : cellData)}
		>
			<TableCell
				align="left"
				className={classNames(
					classes.tableCell,
					classes.flexContainer,
					{[classes.noClick]: onRowClick === null},
					{
						[classes.tableCellBoolTrue]:
							cellData &&
							typeof cellData === 'boolean' &&
							(cellData as boolean) === true,
					},
					{
						[classes.tableCellBoolFalse]:
							cellData &&
							typeof cellData === 'boolean' &&
							(cellData as boolean) === false,
					}
				)}
				onClick={(e) => {
					// if element should be selectable,
					// then avoid triggering the row click on this cell
					if (selectable) {
						e.stopPropagation()
					}
				}}
				component="div"
				style={{height: rowHeight}}
				variant="body"
			>
				<span style={{whiteSpace: 'nowrap'}}>
					{getCellData(cellData)}
					{cellData && suffix ? suffix : null}
				</span>
			</TableCell>
		</Tooltip>
	)
}

interface HandleTableScrollArgs {
	eventData: ScrollEventData
	INFINITE_SCROLL_TRIGGER: number
	hasMoreData: boolean
	busyGettingNextPage: boolean
	handleTableScrollTrigger: () => void
}

export const handleTableScroll = ({
	eventData,
	INFINITE_SCROLL_TRIGGER,
	hasMoreData,
	busyGettingNextPage,
	handleTableScrollTrigger,
}: HandleTableScrollArgs): void => {
	const {clientHeight, scrollHeight, scrollTop} = eventData
	const triggerReached =
		scrollHeight - clientHeight - scrollTop <= INFINITE_SCROLL_TRIGGER

	if (triggerReached && hasMoreData && !busyGettingNextPage) {
		handleTableScrollTrigger()
	}
}

interface GetRowClassNameArgs {
	index: number
	classes: any
	onRowClick: () => void
}

export const getRowClassName = ({
	index,
	classes,
	onRowClick,
}: GetRowClassNameArgs): string => {
	return classNames(classes.tableRow, classes.flexContainer, {
		[classes.tableRowHover]: index !== -1 && onRowClick !== null,
		[classes.headerClass]: index === -1,
	})
}
