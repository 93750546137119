import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomCard functional component
 */

interface CustomCardStylingProps {
	noSidePadding: boolean
}

const useStyles: Styling.US<CustomCardStylingProps> = ({noSidePadding}) =>
	makeStyles((theme: Theme) => ({
		cardContainer: {
			margin: theme.spacing(0, 0),
			padding: theme.spacing(2, noSidePadding ? 0 : 2),
			background: '#fff',
			boxShadow: '0 0 2rem 0 rgba(136,152,170,.15) !important',
			borderRadius: '.375rem',

			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(0, 3),
			},

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4, noSidePadding ? 0 : 3),
			},
		},
	}))()

export default useStyles
