import {Box, Button, Typography} from '@mui/material'
import classNames from 'classnames'
import React, {FC} from 'react'

import useStyles from './style.hook'

type PaginatorProps = {
	className?: string
	currentPage: number
	range?: number
	pageCount: number
	onPageChange: (nextPage: number) => void
}

const BetweenButton = (): React.ReactElement => <Typography>...</Typography>

const Paginator: FC<PaginatorProps> = ({
	className,
	currentPage,
	onPageChange,
	range = 1,
	pageCount,
}) => {
	const classes = useStyles()

	const renderedPages = [...Array(range * 2 + 1).keys()]
		.map((i) => currentPage - range + i)
		.filter((i) => i > 0 && i <= pageCount)

	const showStart = currentPage - range > 1
	const showEnd = currentPage + range < pageCount

	return (
		<Box className={classNames(className, classes.paginator)}>
			{currentPage > 1 && (
				<Button
					className={classNames(
						classes.paginatorButton,
						classes.paginatorArrowButton
					)}
					onClick={(): void => onPageChange(currentPage - 1)}
				>
					&lt;
				</Button>
			)}
			{showStart && (
				<>
					<Button
						className={classNames(
							classes.paginatorButton,
							currentPage === 1 ? classes.currentPageButton : ''
						)}
						onClick={(): void => onPageChange(1)}
					>
						1
					</Button>
					<BetweenButton />
				</>
			)}
			{renderedPages.map((page) => (
				<Button
					className={classNames(
						classes.paginatorButton,
						currentPage === page ? classes.currentPageButton : ''
					)}
					key={page}
					onClick={(): void => onPageChange(page)}
				>
					{page}
				</Button>
			))}
			{showEnd && (
				<>
					<BetweenButton />
					<Button
						className={classNames(
							classes.paginatorButton,
							currentPage === pageCount ? classes.currentPageButton : ''
						)}
						onClick={(): void => onPageChange(pageCount)}
					>
						{pageCount}
					</Button>
				</>
			)}
			{currentPage < pageCount && (
				<Button
					className={classNames(
						classes.paginatorButton,
						classes.paginatorArrowButton
					)}
					onClick={(): void => onPageChange(currentPage + 1)}
				>
					&gt;
				</Button>
			)}
		</Box>
	)
}

export default Paginator
