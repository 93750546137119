import {Tab, TabProps} from '@mui/material'
import {navigate} from 'gatsby'
import React from 'react'

/**
 * LinkTab
 */

interface LinkTabProps extends TabProps {
	to?: string
}

const LinkTab: React.FC<LinkTabProps> = (props: LinkTabProps) => {
	return (
		<Tab
			onClick={(
				event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
			): void => {
				event.preventDefault()
				navigate(props.to)
			}}
			{...props}
		/>
	)
}

export default LinkTab
