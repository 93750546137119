import {darken, Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import {parseToColorString} from '@utils/color.utils'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomButton functional component
 */

interface StyleProps {
	customColor: string
}

const useStyles: Styling.US<StyleProps> = ({customColor}) =>
	makeStyles((theme: Theme) => {
		const backgroundColor = customColor
			? parseToColorString(customColor, theme)
			: theme.palette.primary.main
		return {
			customButton: {
				color: theme.palette.common.white,
				backgroundColor,
				'&:hover': {
					backgroundColor: darken(backgroundColor, 0.2),
				},
				'&:disabled': {
					color: 'rgba(0, 0, 0, 0.87)',
					backgroundColor: '#e0e0e0',
					// this overrides the disabled button in order to show the tool tips
					pointerEvents: 'auto',
				},
			},
			invertedButton: {
				color: darken(backgroundColor, 0.2),
				backgroundColor: theme.palette.common.white,
				border: `1px solid ${darken(backgroundColor, 0.2)}`,
				'&:hover': {
					backgroundColor: darken(theme.palette.common.white, 0.1),
				},
			},
		}
	})()

export default useStyles
