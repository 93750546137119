import React, {useEffect, useState} from 'react'
import {useSSR, useTranslation} from 'react-i18next'

import {LanguageContext} from '@utils/context'

import {DEFAULT_LANGUAGE} from './config'
import allLocales from './locales'
import {changeLanguage, getLangFromBrowser} from './utils'

/**
 * I18nWrapper
 */
interface I18nWrapperProps {
	children: React.ReactNode | unknown
	languageFromPath?: string
}

export const I18nWrapper: React.FC<I18nWrapperProps> = ({
	children,
	languageFromPath,
}) => {
	const {i18n} = useTranslation(undefined, {useSuspense: false})
	const isBrowser = typeof window !== 'undefined'
	const [activeLanguage, setActiveLanguage] = useState<string>(DEFAULT_LANGUAGE)

	useSSR(allLocales, languageFromPath || DEFAULT_LANGUAGE)

	useEffect(() => {
		setActiveLanguage(languageFromPath || getLangFromBrowser())
	}, [languageFromPath])

	// The useEffect hook isn't triggered on SSR, so we do it like this..
	// Looking for a better solution
	if (languageFromPath && !isBrowser) {
		changeLanguage(i18n, languageFromPath)
	}

	return (
		<LanguageContext.Provider value={{activeLanguage, setActiveLanguage}}>
			{children}
		</LanguageContext.Provider>
	)
}

export default I18nWrapper
