import {useMediaQuery, useTheme} from '@mui/material'
import React, {useEffect, useState} from 'react'

import MuiVirtualizedTable, {
	ColumnData,
} from '@components/mui-virtualized-table'
import Paginator from '@components/mui-virtualized-table/paginator'

import useStyles from './style.hook'
import TableSearchHeader from './table-search-header'

/**
 * CustomTable
 */

interface CustomTableProps {
	id: string
	isLoadingData: boolean
	dataEntries: unknown[]
	totalEntries: number
	loadingMessage: string
	tableColumns: ColumnData[]
	columnsToFilter?: ColumnData[]
	noDataMessage: string
	pageLimit: number
	updateCurrentPage: (currentPage: number) => void
	currentPage: number
	itemsPerPage: number
	headerHeight?: number
	rowHeight?: number
	apiCallToExecute: (filter?: {value: string; property: string}) => void
	handleRowClickParent?: (args: unknown) => void
}

const CustomTable: React.FC<CustomTableProps> = ({
	id,
	isLoadingData,
	loadingMessage,
	dataEntries,
	totalEntries,
	tableColumns,
	noDataMessage,
	pageLimit,
	updateCurrentPage,
	currentPage,
	itemsPerPage,
	headerHeight,
	rowHeight,
	apiCallToExecute,
	handleRowClickParent,
	columnsToFilter,
}) => {
	// Get styles from component-scoped styles hook
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
	const classes = useStyles()
	const [filter, setFilter] = useState<{value: string; property: string}>()

	const headerHeightFallback = headerHeight || 40
	const rowHeightFallback = rowHeight || 40

	useEffect((): void => {
		updateCurrentPage(currentPage)
	}, [currentPage, updateCurrentPage])

	useEffect((): void => {
		apiCallToExecute(filter)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageLimit, currentPage, filter])

	const heightFactor =
		dataEntries?.length > itemsPerPage ? itemsPerPage : dataEntries?.length

	// eslint-disable-next-line no-mixed-operators
	const height = rowHeightFallback * heightFactor + headerHeightFallback

	const onPageChange = (page: number): void => {
		updateCurrentPage(page)
	}

	return (
		<div className={classes.marginTop}>
			{columnsToFilter && (
				<TableSearchHeader
					setFilterCallback={setFilter}
					updateCurrentPage={updateCurrentPage}
					columnsToFilter={columnsToFilter}
				/>
			)}
			{isLoadingData ? (
				loadingMessage
			) : dataEntries?.length > 0 ? (
				<div className={classes.marginTop}>
					<MuiVirtualizedTable
						id={id}
						columns={tableColumns}
						minWidth={tableColumns.reduce((a, b) => a + b.minWidth, 0)}
						rowCount={dataEntries.length}
						height={height}
						headerHeight={headerHeightFallback}
						rowHeight={rowHeightFallback}
						rowGetter={({index}): unknown => dataEntries[index]}
						handleRowClickParent={handleRowClickParent}
					/>
					<Paginator
						pageCount={Math.ceil(totalEntries / pageLimit)}
						currentPage={currentPage}
						onPageChange={onPageChange}
						range={isDesktop ? 5 : isMobile ? 2 : 1}
					/>
				</div>
			) : (
				noDataMessage
			)}
		</div>
	)
}

export default CustomTable
