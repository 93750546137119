import {red} from '@mui/material/colors'
import {createTheme} from '@mui/material/styles'

/**
 * Theme definition to provide a global theme which overrides material-ui's default global theme
 * https://mui.com/material-ui/customization/how-to-customize/#global-theme-override
 */

const theme = createTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#fff',
		},
	},
	typography: {
		fontFamily: ['Roboto Mono', 'monospace'].join(','),
	},
	breakpoints: {
		values: {
			xs: 500,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					whiteSpace: 'break-spaces',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					whiteSpace: 'break-spaces',
				},
			},
		},
	},
})

export default theme
