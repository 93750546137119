import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTextInput functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			tabHeader: {
				display: 'grid',
				gridTemplateColumns: '1fr 1fr 1fr 1fr',
				justifyContent: 'center',
				gap: theme.spacing(16 / 8),
				[theme.breakpoints.down('sm')]: {
					gridTemplateColumns: '1fr 1fr',
					alignItems: 'center',
				},
			},
		}
	})()

export default useStyles
