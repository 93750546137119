import {Box, InputLabel, Slider, Typography} from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * CustomSlider
 */

type SliderValue = number | [number, number]

interface CustomSliderProps {
	id?: string
	className?: string
	value: SliderValue
	label: string
	min?: number
	max?: number
	disabled?: boolean
	error?: string
	onChange: (e: React.ChangeEvent<any>, newValue: SliderValue) => void
}

const CustomSlider: React.FC<CustomSliderProps> = ({
	id,
	className,
	value,
	label,
	min,
	max,
	disabled,
	error,
	onChange,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<Box id={id} className={classNames(classes.customSlider, className)}>
			<Box>
				<InputLabel className={classes.label}>{label}</InputLabel>
				<Typography variant="caption">
					{Array.isArray(value)
						? `Min.: ${value[0]}, Max.: ${value[1]}`
						: `Value: ${value}`}
				</Typography>
			</Box>
			<Slider
				value={value}
				min={min}
				max={max}
				disabled={disabled}
				onChange={onChange}
				valueLabelDisplay="auto"
			/>
			<span className={classes.error}>{error}</span>
		</Box>
	)
}

export default CustomSlider
