import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTable functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			marginTop: {
				marginTop: theme.spacing(8 / 8),
			},
		}
	})()

export default useStyles
