import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {InputBase, InputBaseProps, InputLabel} from '@mui/material'
import classnames from 'classnames'
import React from 'react'

import CustomTooltip from '@components/custom-tooltip/custom-tooltip'

import {TooltipValue} from '@utils/tooltipMap'

import useStyles from './style.hook'

/**
 * CustomTextInput
 */

interface CustomTextInputProps {
	id: string
	name?: string
	className?: string
	endAdornment?: React.ReactNode
	placeholder?: string
	value?: string | number
	onChange?: (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void
	label?: string
	disabled?: boolean
	type?: string
	error?: string
	required?: boolean
	onBlur?: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
	propsToDelegate?: InputBaseProps
	tooltipValue?: TooltipValue
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
	id,
	name,
	className,
	endAdornment,
	placeholder,
	value,
	onChange,
	label,
	disabled,
	type,
	required,
	error,
	onBlur,
	propsToDelegate,
	tooltipValue,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div
			id={id}
			className={classnames(classes.customTextInput, {
				[className]: className,
			})}
		>
			<div className={classes.labelContainer}>
				<InputLabel required={required}>{label}</InputLabel>
				<span className={classes.error}>{error}</span>

				{tooltipValue && (
					<CustomTooltip title={tooltipValue}>
						<HelpOutlineIcon
							sx={{
								fontSize: 22,
							}}
						/>
					</CustomTooltip>
				)}
			</div>
			<InputBase
				type={type}
				className={classes.inputBase}
				name={name}
				placeholder={placeholder}
				endAdornment={endAdornment}
				value={value}
				onChange={onChange}
				disabled={disabled}
				onBlur={onBlur}
				{...propsToDelegate}
			/>
		</div>
	)
}

export default CustomTextInput
