import classNames from 'classnames'
import React from 'react'

interface CrossIconSvgProps {
	id?: string
	className?: string
	fill?: string
}

const CrossIconSvg: React.FC<CrossIconSvgProps> = ({id, className, fill}) => {
	return (
		<div id={id} className={classNames(className)}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				className="feather feather-x"
				viewBox="0 0 24 24"
			>
				<path fill={fill || '#000'} d="M18 6L6 18"></path>
				<path fill={fill || '#000'} d="M6 6L18 18"></path>
			</svg>
		</div>
	)
}

export default CrossIconSvg
