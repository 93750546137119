import {Box, Modal, Typography} from '@mui/material'
import {Permissions} from '@roles/permissions'
import {roleHasPermission} from '@roles/roles'
import React, {useMemo} from 'react'

import {
	useGetAuthStateQuery,
	useLazyLoginQuery,
	useLazyLogoutQuery,
} from '@redux/slices/auth.slice'

import Unauthorised from '@templates/unauthorised'

import {CustomButton} from '@components/index'

import {isBrowser} from '@utils/browser-utils'

import useStyles from './style.hook'

/**
 * AuthWrapper
 */

interface AuthWrapperProps {
	children: React.ReactNode
	isPrivateRoute: boolean
	requiredPermissions?: Permissions[]
	location: Location
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({
	children,
	isPrivateRoute,
	requiredPermissions,
	location,
}) => {
	const classes = useStyles()

	const {
		data: authStatus,
		isUninitialized,
		isFetching,
		isSuccess,
	} = useGetAuthStateQuery(null)
	const [login] = useLazyLoginQuery()

	const [logout] = useLazyLogoutQuery()

	const isUnauthorized = useMemo(
		() =>
			isPrivateRoute &&
			!isUninitialized &&
			!isFetching &&
			isSuccess &&
			authStatus?.type === 'UNAUTHORISED',
		[isPrivateRoute, isUninitialized, isFetching, isSuccess, authStatus]
	)

	const waitingComponent: JSX.Element = null

	const componentToRender = (
		<React.Fragment>
			<div className={classes.rolesAndLogout}>
				{location.pathname !== '/admin-login' && (
					<>
						{authStatus?.type === 'AUTHORISED' && (
							<CustomButton
								onClick={(): void =>
									logout(authStatus.userData.isElautUser ? 'elaut' : 'tenant')
								}
							>
								Logout
							</CustomButton>
						)}
					</>
				)}
			</div>
			<Modal className={classes.loginModal} open={isUnauthorized}>
				<Box className={classes.modalContent}>
					<Typography variant={'body1'} className={classes.modalTitle}>
						Please Log In
					</Typography>
					<CustomButton
						id="login-modal-login-button"
						onClick={(): void => login('tenant')}
					>
						Login
					</CustomButton>
				</Box>
			</Modal>
			{children}
		</React.Fragment>
	)

	const unauthorisedComponent = <Unauthorised />

	if (isBrowser) {
		if (isPrivateRoute) {
			if (isFetching) {
				return waitingComponent
			}

			if (
				requiredPermissions &&
				authStatus.userData?.role &&
				requiredPermissions
					.map((p) => roleHasPermission(authStatus.userData?.role, p))
					?.some((v) => !v)
			) {
				return unauthorisedComponent
			}

			return componentToRender
		}

		return componentToRender
	}
}

export default AuthWrapper
