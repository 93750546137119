import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTextInput functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			customTextInput: {
				width: '100%',
			},
			labelContainer: {
				display: 'flex',
				gap: theme.spacing(8 / 8),
				color: '#586483',
				fontSize: theme.spacing(18 / 8),
				alignItems: 'center',
			},
			error: {
				'&::after': {
					content: 'attr(data-error)',
					top: 0,
					fontSize: '0.875rem',
					color: '#dc3545',
					right: 5,
					transform: 'translateY(calc(-75%))',
					marginLeft: theme.spacing(5 / 8),
					maxWidth: theme.spacing(180 / 8),
				},
			},
		}
	})()

export default useStyles
