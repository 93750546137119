import {QueryReturnValue} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {BaseQueryFn, fetchBaseQuery} from '@reduxjs/toolkit/query'
import {Mutex} from 'async-mutex'

import {DEFAULT_HEADERS} from './config'
import {isErrorResponse} from './error-functions'

// This mutex is used to prevent multiple refresh token requests
// The mutex holds a promise that is resolved when the refresh token request is done
const refreshMutex = new Mutex()
const clearUserMutex = new Mutex()

export const jsonBaseQuery =
	(): BaseQueryFn<{
		input: RequestInfo
		init?: RequestInit
	}> =>
	async ({input, init}, api, extraOptions): Promise<QueryReturnValue> => {
		try {
			// Wait for a potential refresh token request to finish
			await refreshMutex.waitForUnlock()
			const lastUserIdpId = window.localStorage.getItem('lastUserIdpId')

			const clearUserData = () => {
				// Do not redirect if the call is /me, this will cause an infinite loop
				// Also do not redirect if the user is already being redirected
				if (!clearUserMutex.isLocked() && api.endpoint !== 'getAuthState') {
					clearUserMutex.acquire().then(async (release) => {
						window.localStorage.removeItem('lastUserIdpId')
						window.location.href = '/'
						release()
					})
				}
			}

			const fetchQuery = async () => {
				// Using the base query function from redux toolkit instead of normal fetch
				// This returns the metadata as well as the response
				return fetchBaseQuery({
					baseUrl: process.env.GATSBY_BACKOFFICE_BASE_URL,
				})(
					{
						...init,
						url: input as string,
						headers: {...DEFAULT_HEADERS, ...init.headers},
						credentials: init.credentials || 'include',
					},
					api,
					extraOptions
				)
			}

			// fetch the query first time
			let result = await fetchQuery()

			const fetchRefreshToken = async () => {
				// return Promise.resolve({ok: false, status: 401})
				return fetch(
					`${process.env.GATSBY_AUTH_API_BASE_URL}/auth/${lastUserIdpId}/refresh?backoffice=true`,
					{
						method: 'GET',
						credentials: 'include',
					}
				)
			}

			// If the query returns a 401, try to refresh the token if it's not already being refreshed
			if (
				result.error &&
				result.meta.response.status === 401 &&
				!refreshMutex.isLocked()
			) {
				// Lock the mutex
				const release = await refreshMutex.acquire()
				// Fetch the refresh token
				const refreshResponse = await fetchRefreshToken()
				// If the refresh token request fails, clear the user data
				if (!refreshResponse.ok || refreshResponse.status === 401) {
					release()
					clearUserData()
				} else {
					// Retry the query and clear the user data if it fails
					result = await fetchQuery()
					release()
					result.meta.response.status === 401 && clearUserData()
				}

				// if the query returns a 401 and the refresh token is already being refreshed, wait for the refresh token request to finish
			} else if (result.meta.response.status === 401) {
				await refreshMutex.waitForUnlock()
				// Retry the query and clear the user data if it fails
				result = await fetchQuery()
				result.meta.response.status === 401 && clearUserData()
			}
			if (isErrorResponse(result.meta.response)) {
				return {error: result.data ?? result.error?.data, meta: result.meta}
			}

			return {...result}
		} catch (error) {
			return {error: error.message}
		}
	}

export const jsonMockBaseQuery =
	(): BaseQueryFn<{
		input: RequestInfo
		init?: RequestInit
	}> =>
	async (): Promise<QueryReturnValue> => {
		try {
			const result = {
				json: (): string =>
					JSON.stringify({
						name: 'plushie grabber',
					}),
			}

			const jsonResult: unknown = await result.json()

			return {data: jsonResult}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}
