import React, {useEffect} from 'react'
import toast, {Toaster} from 'react-hot-toast'
import {useDispatch, useSelector} from 'react-redux'

import errorsSlice, {
	selectErrorsWithoutViolations,
} from '@redux/slices/error.slice'

import useStyles from './style.hook'

/**
 * NotifcationLayout
 */

interface NotifcationLayoutProps {
	children?: React.ReactNode
}

const NotifcationLayout: React.FC<NotifcationLayoutProps> = ({children}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const dispatch = useDispatch()

	const errors = useSelector(selectErrorsWithoutViolations)

	useEffect(() => {
		if (errors?.length === 0) {
			return
		}

		const timeout = setTimeout(() => {
			errors.map((error: BackendErrorType): string =>
				toast.error(error.message || 'something went wrong', {
					duration: 5000,
				})
			)

			dispatch(errorsSlice.actions.resetErrorsWithoutViolations())
		}, 1000)

		return (): void => {
			clearTimeout(timeout)
		}
	}, [dispatch, errors])

	return (
		<div className={classes.ticketAnimationLayer}>
			<Toaster
				position={'top-center'}
				containerStyle={{position: 'absolute', inset: '0 !important'}}
			/>
			{children}
		</div>
	)
}

export default React.memo(NotifcationLayout)
