import {Box} from '@mui/material'
import classNames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * CustomCard
 */

interface CustomCardProps {
	className?: string
	noSidePadding?: boolean
	children: React.ReactNode
}

const CustomCard: React.FC<CustomCardProps> = ({
	className,
	children,
	noSidePadding = false,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles({noSidePadding})

	return (
		<Box className={classNames(className, classes.cardContainer)}>
			{children}
		</Box>
	)
}

export default CustomCard
