import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTextInput functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			tooltip: {
				fontSize: theme.spacing(14 / 8),
			},
		}
	})()

export default useStyles
