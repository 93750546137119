import {lighten, Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomDialog functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		dialog: {
			'& .MuiDialog-paper': {
				padding: theme.spacing(20 / 8),
				paddingTop: theme.spacing(30 / 8),
				minWidth: theme.spacing(350 / 8),
			},
		},
		title: {
			fontSize: '20px',
			fontWeight: 600,
			marginBottom: theme.spacing(20 / 8),
			textAlign: 'center',
		},
		closeButton: {
			position: 'absolute',
			top: theme.spacing(8 / 8),
			right: theme.spacing(8 / 8),
			color: lighten('#000', 0.5),
			'&:hover': {
				cursor: 'pointer',
				color: lighten('#000', 0.4),
			},
		},
		buttonContainer: {
			marginTop: theme.spacing(20 / 8),
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gap: theme.spacing(10 / 8),
		},
	}))()

export default useStyles
