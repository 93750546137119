import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside CustomTextInput functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			customTextInput: {
				width: '100%',
				borderRadius: theme.spacing(6 / 8),
				padding: theme.spacing(5 / 8),

				// Gradient border

				'& .MuiInputBase-root': {
					border: '1px solid #DBDFE4 ',
					width: '100%',
					borderRadius: 'inherit',
					padding: theme.spacing(10 / 8),
				},

				'& .MuiInputBase-input': {
					padding: 0,
					fontSize: 18,
					lineHeight: '21.6px',
					color: '#586483',
				},
				'& .Mui-disabled': {
					color: 'black',
					border: 'none',
				},
			},
			labelContainer: {
				display: 'flex',
				gap: theme.spacing(8 / 8),
				marginBottom: theme.spacing(10 / 8),
				color: '#586483',
				fontSize: theme.spacing(18 / 8),
			},
			error: {
				color: '#dc3545',
			},
		}
	})()

export default useStyles
