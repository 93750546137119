import {Theme} from '@mui/material'

export type ThemeColorDefinition = 'green' | 'orange' | 'red'

export type ColorDefinition = ThemeColorDefinition | string

export const colorToStringMap = (theme: Theme): Map<ColorDefinition, string> =>
	new Map<ColorDefinition, string>([
		['green', theme.palette.success.main],
		['orange', theme.palette.warning.main],
		['red', '#ef5350'],
	])

export const parseToColorString = (
	color: ColorDefinition,
	theme: Theme
): string => {
	return colorToStringMap(theme).get(color) || color
}

export const checkStockColor = (
	actualStock: number,
	minStock: number
): StockColorType => {
	if (actualStock <= 0) {
		return 'red'
	}

	if (actualStock <= minStock) {
		return 'orange'
	}

	if (actualStock > minStock) {
		return 'green'
	}
}
