import {combineReducers} from '@reduxjs/toolkit'

import errorsSlice from './slices/error.slice'
import {authApi, baseApi} from './slices/index'

const rootReducer = combineReducers({
	[baseApi.reducerPath]: baseApi.reducer,
	[authApi.reducerPath]: authApi.reducer,
	[errorsSlice.name]: errorsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
