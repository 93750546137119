import classNames from 'classnames'
import React from 'react'

import useStyles from './style.hook'

/**
 * CustomGrid
 */

interface CustomGridProps {
	columns?: string
	children: React.ReactNode
	className?: string
}

const CustomGrid: React.FC<CustomGridProps> = ({
	columns,
	children,
	className,
}) => {
	const classes = useStyles({columns})

	return <div className={classNames(classes.grid, className)}>{children}</div>
}

export default CustomGrid
