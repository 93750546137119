import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {Checkbox, FormControl, FormGroup, FormLabel} from '@mui/material'
import classnames from 'classnames'
import React from 'react'

import CustomTooltip from '@components/custom-tooltip/custom-tooltip'

import {TooltipValue} from '@utils/tooltipMap'

import useStyles from './style.hook'

/**
 * CustomTextInput
 */

interface CustomCheckboxInputProps {
	id: string
	className?: string
	name?: string
	value?: boolean
	label?: string
	disabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	error?: string
	tooltipValue?: TooltipValue
}

const CustomCheckboxInput: React.FC<CustomCheckboxInputProps> = ({
	id,
	className,
	name,
	value,
	label,
	disabled,
	onChange,
	error,
	tooltipValue,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<div
			id={id}
			className={classnames(classes.customTextInput, {
				[className]: className,
			})}
		>
			<FormControl>
				<FormGroup aria-label="position" row>
					<FormLabel
						className={classnames(classes.label, {
							[classes.error]: error,
						})}
						data-error={error}
					>
						<div className={classes.labelContainer}>
							<Checkbox
								name={name}
								onChange={onChange}
								color="primary"
								checked={value}
								disabled={disabled}
							/>
							<span>{label}</span>

							{tooltipValue && (
								<CustomTooltip title={tooltipValue}>
									<HelpOutlineIcon
										sx={{
											fontSize: 22,
										}}
									/>
								</CustomTooltip>
							)}
						</div>
					</FormLabel>
				</FormGroup>
			</FormControl>
		</div>
	)
}

export default CustomCheckboxInput
