import makeStyles from '@mui/styles/makeStyles'
import {useCallback} from 'react'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside TicketAnimationLayer functional component
 */

const useStyles: Styling.US = () => {
	const stylesHook = useCallback(
		makeStyles(() => ({
			ticketAnimationLayer: {
				'&&': {
					position: 'absolute !important',
					inset: '52px !important',
					pointerEvents: 'none',
				},
			},
			customToast: {
				position: 'absolute',
				opacity: '1 !important',
				left: 12,
				bottom: 36,
			},
		})),
		[]
	)

	return stylesHook()
}

export default useStyles
