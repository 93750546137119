import {Theme} from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import Styling from '@styles/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside HeaderLayout functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		sidebarLayout: {
			'& .MuiPaper-root': {
				display: 'block',
				position: 'fixed',
				top: 0,
				bottom: 0,
				width: '100%',
				maxWidth: '250px',
				overflowY: 'auto',
				boxShadow: '0 0 2rem 0 rgba(136,152,170,.15) !important',
				border: 'none',
			},
			'& a': {
				color: theme.palette.common.black,
				textDecoration: 'none',
			},
		},

		sidebarHeader: {
			display: 'flex',
			padding: theme.spacing(3, 2, 0),
			marginBottom: theme.spacing(5),
		},

		sidebarMenuItem: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline',
		},

		drawerItem: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',

			'& div': {
				display: 'flex',

				'& svg': {
					alignSelf: 'center',
				},
			},

			'& svg': {
				alignSelf: 'center',
			},
		},

		activeItem: {
			'&::before': {
				content: '""',
				position: 'absolute',
				left: -16,
				top: 0,
				bottom: 0,
				borderLeft: '4px solid #0A48B3',
			},
		},

		itemLogo: {
			alignSelf: 'center',
			minWidth: '2.25rem',
			fontSize: '0.938rem',
		},

		submenu: {
			width: '100%',
		},

		mobileDrawer: {
			display: 'none',
			'& .MuiPaper-root': {
				display: 'block',
				position: 'fixed',
				top: 0,
				bottom: 0,
				width: '100%',
				maxWidth: '250px',
				overflowY: 'auto',
			},
			'& a': {
				color: theme.palette.common.black,
				textDecoration: 'none',
			},
			[theme.breakpoints.down('lg')]: {
				display: 'block',
			},
		},
		menuItem: {
			display: 'none',
			position: 'absolute',
			left: theme.spacing(8 / 8),
			top: theme.spacing(8 / 8),
			[theme.breakpoints.down('lg')]: {
				display: 'block',
			},
		},
		desktopDrawer: {
			display: 'block',
			[theme.breakpoints.down('lg')]: {
				display: 'none',
			},
		},
	}))()

export default useStyles
